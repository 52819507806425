/*video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }*/
  
  .hero-container {
    background: url('/public/images/background.jpg') center center/cover no-repeat;
    background-attachment: fixed;
    height: 93vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0  0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    font-family: 'Jost', sans-serif;
    font-weight: 'extrabold';
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 'regular';
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  .button {
    background: rgba(0, 0, 0, 0);
    background-color: none;
    border: none;
    color: rgb(143, 139, 144);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    margin: 4px 2px;
    border-radius: 100%;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }

    .hero-container > p {
      margin-top: 8px;
      max-width: 70%;
      font-family: 'Noto Sans', sans-serif;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: 5px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }